import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Image from "next/image";
import Link from "next/link";
import { aspectRatio } from "../../../css/content";
import { Meta, HeadingS } from "../../../css/typography";
import { getDateFromTime } from "../../../lib/date";
import { useRouter } from "next/router";
import { loading } from "../../../css/loading";
import { NewsTag } from "../news-tag/NewsTag";
import { fallbackImage } from "../../../lib/image";
import { NEWS_TYPE_MAIN } from "../../../lib/context/news/NewsProvider";
import { metadataFromLink } from "../../../lib/api/metadata";

const CardWrapper = styled.a`
    display: flex;
    background-color: ${p => p.theme.gray50};
    transition: transform 0.3s, box-shadow 0.3s;
    will-change: transform, box-shadow;
    backface-visibility: hidden;

    @media (hover: hover) {
        &:hover {
            box-shadow: ${p => p.theme.cardShadow};
            transform: scale(1.025);
        }
    }
`;

const CardInner = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    transform: translateZ(0);
    backface-visibility: hidden;
`;

const CardImageWrapper = styled.div<{ $loading?: boolean }>`
    width: 100%;
    ${aspectRatio(0.55)};
    background-color: ${p => p.theme.gray200};
    ${p => p.$loading && loading};
`;

const CardContent = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 2rem;
    width: 100%;
`;

const CardDate = styled.div<{ $loading?: boolean }>`
    display: flex;
    margin-bottom: 0.6rem;
    color: ${p => p.theme.gray600};
    opacity: ${p => p.$loading && 0};
`;

export const CardTitle = styled.div<{ $loading?: boolean }>`
    ${p => p.$loading && loading};
`;

const CardText = styled.div<{ $loading?: boolean }>`
    display: none;
    flex: 1;
    margin-top: 0.8rem;
    width: 100%;
    color: ${p => p.theme.gray600};
    ${p => p.$loading && loading};
    min-height: ${p => p.$loading && "13.5rem"};

    @media ${p => p.theme.bp.l} {
        display: block;
    }
`;

const CardTags = styled.div<{ $loading?: boolean }>`
    display: flex;
    flex-wrap: wrap;
    gap: 0.6rem;
    margin-top: 1.2rem;
    opacity: ${p => p.$loading && 0};
`;

const ExternalImage = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

export const NewsCard: React.FC<News.Main> = news => {
    const router = useRouter();
    const content = news.content;
    const [metadataLoading, setMetadataLoading] = useState<boolean>(true);
    const [metadata, setMetadata] = useState<News.Metadata | null>(null);

    useEffect(() => {
        if (content && content["__typename"] === "NewsExternalArticle") {
            metadataFromLink(content.link).then(data => {
                setMetadata(data);
                setMetadataLoading(false);
            });
        }
    }, [news]);

    const mainTags = news.mainTagsCollection?.items || [];
    const subTags = news.subTagsCollection?.items || [];

    if (!content) return null;

    return content["__typename"] === "NewsExternalArticle" ? (
        <CardWrapper href={content.link} target="_blank" rel="noreferrer noopener">
            <CardInner>
                {!metadataLoading && metadata ? (
                    <CardImageWrapper>
                        <ExternalImage
                            src={content.mainImage || metadata.image || fallbackImage}
                            alt={metadata.title ?? ""}
                            loading="lazy"
                        />
                    </CardImageWrapper>
                ) : (
                    <CardImageWrapper $loading />
                )}
                <CardContent>
                    <CardDate>
                        <Meta>{getDateFromTime(news.sys.firstPublishedAt, router.locale)}</Meta>
                    </CardDate>
                    {!metadataLoading && metadata ? (
                        <CardTitle>
                            <HeadingS>{content.title || metadata.title}</HeadingS>
                        </CardTitle>
                    ) : (
                        <CardTitle $loading>Loading</CardTitle>
                    )}
                    {!metadataLoading && metadata ? (
                        <CardText>{content.teaserText || metadata.description}</CardText>
                    ) : (
                        <CardText $loading />
                    )}
                    <CardTags>
                        {[...mainTags, ...subTags].map(tag => (
                            <NewsTag key={tag.sys.id}>{tag.title}</NewsTag>
                        ))}
                    </CardTags>
                </CardContent>
            </CardInner>
        </CardWrapper>
    ) : (
        <Link
            href={{ query: { ...router.query, s: NEWS_TYPE_MAIN + ":" + news.slug } }}
            shallow
            passHref>
            <CardWrapper>
                <CardInner>
                    <CardImageWrapper>
                        <Image
                            src={
                                content.image?.imageUrl ||
                                content.newsTemplate?.mainImage?.url ||
                                fallbackImage
                            }
                            alt={news.content.title ?? ""}
                            layout="fill"
                            objectFit="cover"
                            sizes="600px"
                        />
                    </CardImageWrapper>
                    <CardContent>
                        <CardDate>
                            <Meta>{getDateFromTime(news.sys.firstPublishedAt, router.locale)}</Meta>
                        </CardDate>
                        <CardTitle>
                            <HeadingS>{news.content.title}</HeadingS>
                        </CardTitle>
                        <CardText>{content.teaserText}</CardText>
                        <CardTags>
                            {[...mainTags, ...subTags].map(tag => (
                                <NewsTag key={tag.sys.id}>{tag.title}</NewsTag>
                            ))}
                        </CardTags>
                    </CardContent>
                </CardInner>
            </CardWrapper>
        </Link>
    );
};

export const NewsCardSkeleton: React.FC = () => (
    <CardWrapper as="div">
        <CardInner>
            <CardImageWrapper $loading />
            <CardContent>
                <CardDate $loading>Loading</CardDate>
                <CardTitle $loading>Loading</CardTitle>
                <CardText $loading />
                <CardTags $loading>
                    <NewsTag>Loading</NewsTag>
                </CardTags>
            </CardContent>
        </CardInner>
    </CardWrapper>
);
