import React from "react";
import styled from "styled-components";
import { HeadingM } from "../../../css/typography";
import { ArrowRight } from "../../../icon/ArrowRight";
import { NewsCard, NewsCardSkeleton } from "../../atom/news-card/NewsCard";
import { useTranslation } from "next-i18next";

const GridWrapper = styled.div``;

const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1.8rem;

    @media ${p => p.theme.bp.m} {
        grid-template-columns: repeat(auto-fill, minmax(32.2rem, 1fr));
    }
`;

const GridHead = styled.div<{ $loading?: boolean }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    opacity: ${p => p.$loading && 0};
`;

const MoreButton = styled.button`
    display: flex;
    align-items: center;
    color: ${p => p.theme.gray600};
    transition: color 0.1s;
    will-change: color;

    @media (hover: hover) {
        &:hover {
            color: ${p => p.theme.gray900};
        }
    }
`;

const ArrowIcon = styled(ArrowRight)`
    width: 1.8rem;
    height: 1.3rem;
    margin-left: 0.8rem;
`;

const NewsEmpty = styled.div`
    display: flex;
    justify-content: center;
    margin: 2.5rem 0 12.5rem;
    color: ${p => p.theme.gray700};
`;

export interface TilesGridProps {
    news: News.Main[];
    title?: string;
    onSelectCategory?: () => void;
}

export const NewsGrid: React.FC<TilesGridProps> = ({ news, title, onSelectCategory }) => {
    const { t } = useTranslation();

    return (
        <GridWrapper>
            {title && (
                <GridHead>
                    <HeadingM>{title}</HeadingM>
                    {news.length > 0 && onSelectCategory && (
                        <MoreButton type="button" onClick={onSelectCategory}>
                            {t("showAllNews")}
                            <ArrowIcon />
                        </MoreButton>
                    )}
                </GridHead>
            )}
            {news.length > 0 ? (
                <Grid>
                    {news.map(article => (
                        <NewsCard {...article} key={article.sys.id} />
                    ))}
                </Grid>
            ) : (
                <NewsEmpty>{t("noResultsFound")}</NewsEmpty>
            )}
        </GridWrapper>
    );
};

interface TilesGridSkeletonProps {
    amount?: number;
}

export const TilesGridSkeleton: React.FC<TilesGridSkeletonProps> = ({ amount = 6 }) => {
    return (
        <GridWrapper>
            <GridHead $loading>
                <HeadingM>Loading</HeadingM>
            </GridHead>
            <Grid>
                {[...Array(amount)].map((item, index) => (
                    <NewsCardSkeleton key={index} />
                ))}
            </Grid>
        </GridWrapper>
    );
};
